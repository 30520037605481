import React, {FC, useEffect, useState} from 'react'
import {Beverage, Buy} from '../../PloneApi'
import {backendURL} from '../../helpers'
import {Col, Container, Row} from 'react-bootstrap'
import BeverageCard from './BeverageCard'
import StatisticsCard from "./StatisticsCard";
import {FilterDropdown} from "./FilterDropdown";
import {SortingDropdown} from "./SortingDropdown";
import {useGlobalState} from "../../state";

const Dashboard: FC = () => {
    const greetings = [
        'Prost!',
        'Runter damit!',
        'Nachschub gefällig?!'
    ]

    const [showStatistic] = useState<Boolean>(true)
    const [statisticPosition, setStatisticPosition] = useState<Number>(0)
    const [filteredBeverages, setFilteredBeverages] = useState<Beverage[]>([])
    const [sortedBeverages, setSortedBeverages] = useState<Beverage[]>([])
    const {session, user, beverages, setBeverages} = useGlobalState()
    const [greeting] = useState(greetings[Math.round(Math.random() * (greetings.length - 1))])

    useEffect(() => {
        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "get_beverages",
                "method_data": null
            })
        })
            .then((res) => res.json() as Promise<Beverage[]>)
            .then(newBeverages => {
                setBeverages(newBeverages!)
                if (newBeverages)
                    setStatisticPosition(Math.round(Math.random() * (newBeverages.length - 1)))
            })
    }, [session, setBeverages])

    const buy = async (ean: string, amount: number = 1, username: string = user?.username ?? 'guest'): Promise<void> => {
        fetch(`${backendURL}/james_api`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "take_beverage",
                "method_data": {
                    'ean': ean,
                    'amount': amount,
                    'username': username,
                }
            })
        })
            .then((response) => response.json() as Promise<Buy.Response>)
            .then(buyResponse => {
                setBeverages(prev => {
                    return prev.map((bev): Beverage => {
                        if (bev.ean === buyResponse.ean)
                            return {
                                ...bev,
                                stock: buyResponse.stock
                            }
                        else return bev
                    })
                })
            })
    }

    const getCard = (bev: Beverage, idx: number): JSX.Element => {
        if (idx === statisticPosition && showStatistic && user?.showStatistics)
            return <>
                <StatisticsCard key={idx}/>
                <BeverageCard beverage={bev} buy={buy} key={idx}/>
            </>
        return <BeverageCard beverage={bev} buy={buy} key={idx}/>
    }

    return (
        <>
            <Container fluid className={"sticky"}>
                <Row>
                    <Col className={'dashboard-header'} xs={12}>{greeting}</Col>
                </Row>
                <Row className={'beverage-filters'}>
                    <Col xs={12} md={4} lg={2}>
                        <FilterDropdown beverages={beverages} filteredBeverages={setFilteredBeverages}/>
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        <SortingDropdown beverages={filteredBeverages} sortedBeverages={setSortedBeverages}/>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    {sortedBeverages.map((beverage, idx) => getCard(beverage, idx))}
                </Row>
            </Container>
        </>
    )
}

export default Dashboard

import React, {FC} from 'react'

import PaymentMenueBody from "./PaymentMenueBody";


const PaymentMenue: FC = () => {
    return (
        <PaymentMenueBody show={true} />
    )
}

export default PaymentMenue


import {FC, useEffect, useState} from 'react'
import {Modal, ModalBody, Table} from 'react-bootstrap'
import BeverageRow from "./BeverageRow";
import {useGlobalState} from "../../state";
import {Beverage} from "../../PloneApi";

const BeverageOverview: FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {
    const {beverages} = useGlobalState()
    const [displayedBeverage, setDisplayedBeverages] = useState<Beverage[]>(beverages)
    const [query, setQuery] = useState<string>('')


    useEffect(() => {
        setDisplayedBeverages(beverages)
    }, [beverages])

    useEffect(() => {
        setDisplayedBeverages(beverages.filter((beverage) => JSON.stringify(beverage).includes(query)))
    }, [query, beverages])

    return (
        <Modal show={show} onHide={hide} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Lagerbestand Übersicht</Modal.Title>
            </Modal.Header>
            <Modal.Header className="d-flex justify-content-start" style={{columnGap: "8px"}}>
                <input
                    type={'text'}
                    value={query}
                    placeholder={'Suchen...'}
                    onChange={(e) => {
                        setQuery(e.target.value)

                    }}
                />
            </Modal.Header>
            <ModalBody className={"beverageOverview"}>
                <Table hover={true} responsive>
                    <thead>
                    <tr>
                        {['Name', 'Lagerbestand', 'Veranschaulicht', 'Benötigt', 'Nachbestell Preis', 'Optionen', 'EAN'].map((col, idx) =>
                            <th key={idx}>{col}</th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        displayedBeverage.map((beverage, idx) => <BeverageRow beverage={beverage}
                                                                              key={idx}/>)
                    }
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}

export default BeverageOverview

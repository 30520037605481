import {Table} from 'react-bootstrap';

const Nutritions = ({nutritions}: { nutritions: Record<string, any> }): JSX.Element => {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Nährwert</th>
                    <th>Menge</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(nutritions).map(([key, val]) => (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>{val}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default Nutritions;
import {FC, useState} from 'react'
import {Button, ButtonGroup, Image} from 'react-bootstrap'
import {Gear} from 'react-bootstrap-icons'
import UserSettings from './settings/user/UserSettings'
import PaymentMenueModal from "./payment/PaymentMenueModal";
import BeverageOverview from "./admin/BeverageOverview";
import ManageBillsModal from './manage/ManageBillsModal'
import {useGlobalState} from "../state";


const UserButton: FC = () => {
    const { user , resetUser , setSession} = useGlobalState()
    const [showMenu, setShowMenu] = useState(false)
    const toggleMenu = () => setShowMenu(!showMenu)
    const [showUserSettings, setShowUserSettings] = useState(false)
    const [showPaymentMenueModal, setShowPaymentMenueModal] = useState(false)

    const toggleUserSettings = () => setShowUserSettings(!showUserSettings)
    const [showManageUserOrders, setShowManageUserOrders] = useState(false)
    const toggleManageUserOrders = () => setShowManageUserOrders(!showManageUserOrders)
    const togglePaymentMenueModal = () => setShowPaymentMenueModal(!showPaymentMenueModal)

    const [showAdminSettings, setShowAdminsSettings] = useState(false)
    const toggleAdminSettings = () => setShowAdminsSettings(!showAdminSettings)
    const toggleShowManageUserOrders = () => setShowManageUserOrders(!showManageUserOrders)



    return (
        <>
            <div id="user-button">
                <div className="button-wrapper" aria-controls="user-menu">
                    <Image className="shadow-lg"
                           src={user?.img}
                           alt="Profilbild"
                           onClick={toggleMenu}
                    />
                    <Gear onClick={toggleMenu} />
                </div>
                <ButtonGroup id="user-menu" aria-expanded={showMenu}>
                    <Button variant="danger" onClick={() => {
                        setSession(null)
                        resetUser()
                    }} id="logout">Logout</Button>
                    {
                        user?.isAdmin ?
                            <>
                                <Button onClick={toggleShowManageUserOrders} id="usersettings">
                                    Benutzer-Bestellungen
                                </Button>
                                <Button onClick={toggleAdminSettings} variant="secondary" id="show-stock">
                                    Lagerbestand-Übersicht
                                </Button>
                            </>
                            : ''
                    }
                    <Button onClick={toggleUserSettings}>
                        Einstellungen
                    </Button>
                    <Button onClick={togglePaymentMenueModal} style={{paddingRight: "40px"}} variant="primary">
                        Rechnungen
                    </Button>
                </ButtonGroup>
            </div>
            <UserSettings show={showUserSettings} hide={toggleUserSettings} />
            {
                user?.isAdmin ?
                    <BeverageOverview show={showAdminSettings} hide={toggleAdminSettings} /> : <></>

            }
            <PaymentMenueModal show={showPaymentMenueModal} hide={togglePaymentMenueModal} />
            <ManageBillsModal show={showManageUserOrders} hide={toggleManageUserOrders} />
        </>
    )
}

export default UserButton;
import {FC, useState} from 'react'
import {Beverage} from "../../PloneApi";
import {Button, ProgressBar} from "react-bootstrap";
import {backendURL} from "../../helpers";
import {useGlobalState} from "../../state";
import {Trash} from "react-bootstrap-icons";

const BeverageRow: FC<{ beverage: Beverage }> = ({beverage}) => {

    const {session, updateBeverage} = useGlobalState()
    const [newStock, setNewStock] = useState<string>(beverage.stock.toString())
    const changeStock = () => {
        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "set_beverage_attribute",
                "method_data": {
                    "ean": beverage.ean,
                    "attr_name": 'stock',
                    "attr_value": parseInt(newStock)
                }
            })
        })
            .then((response) => response.json() as Promise<Beverage>)
            .then((newBeverage) => {
                    updateBeverage(beverage.ean, newBeverage)
                }
            )
    }


    return <tr>
        <td style={{verticalAlign: "middle"}}>
            {
                beverage.name
            }
        </td>
        <td style={{verticalAlign: "middle"}}>
            {
                beverage.stock
            }
        </td>
        <td style={{verticalAlign: "middle"}}>
            {
                <ProgressBar variant={beverage.stock / beverage.needed * 100 > 30 ? "success" : "warning"}
                             now={beverage.stock / beverage.needed * 100}/>
            }
        </td>
        <td style={{verticalAlign: "middle"}}>
            {
                beverage.needed
            }
        </td>
        <td style={{verticalAlign: "middle"}}>
            {
                beverage.orderprice
            }
            €
        </td>
        <td style={{verticalAlign: "middle", gap: '10px'}}>
            <div style={{display: "flex", justifyContent: 'start', alignItems: 'center', gap: '10px'}}>
                <label>
                    Neuer Bestand: <input
                        style={{minHeight: '100%'}}
                        type={'number'}
                        value={newStock}
                        placeholder={'stock'}
                        onChange={(e) => {
                            setNewStock(e.target.value)
                        }}
                    />
                </label>
                <Button variant="primary"
                        size="sm"
                        onClick={() => changeStock()}>
                    Lagerbestand Ändern
                </Button>
                <Button variant="primary"
                        size="sm"
                        onClick={() => alert("Derzeit wird die Nachbestellung noch nicht unterstützt")}
                        disabled>
                    Nachbestellen
                </Button>
                <Button variant="danger"
                        size="sm"
                        onClick={() => alert("Du solltest noch keine Getränke löschen.")}
                        disabled>
                    <Trash/>
                </Button>
            </div>
        </td>
        <td style={{verticalAlign: "middle"}}>
            {
                beverage.ean
            }
        </td>
    </tr>
}

export default BeverageRow
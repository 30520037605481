import React, {FC, useEffect, useState} from "react";
import {Beverage} from "../../PloneApi";
import {Dropdown} from "react-bootstrap";
import {
    Filter,
    SortAlphaDownAlt,
    SortAlphaUp,
    SortDown,
    SortNumericDown,
    SortNumericUp,
    SortUp
} from "react-bootstrap-icons";

export const SortingDropdown: FC<{ beverages: Beverage[], sortedBeverages: Function }> = ({
                                                                                              beverages,
                                                                                              sortedBeverages
                                                                                          }) => {
    const [sorting, setSorting] = useState<0 | "priceasc" | "pricedesc" | "alphabeticalasc" | "alphabeticaldesc" | "stockasc" | "stockdesc">(0)
    const [buttonText, setButtonText] = useState<JSX.Element>()

    useEffect(() => {
        sort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting, beverages])

    function sort() {
        let sorted: Beverage[] = beverages
        switch (sorting) {
            case "priceasc":
                sorted = [...beverages.sort((a, b) => a.price - b.price)]
                setButtonText(<>Preis Aufsteigend <SortUp /></>)
                break;
            case "pricedesc":
                sorted = [...beverages.sort((a, b) => b.price - a.price)]
                setButtonText(<>Preis Absteigend <SortDown /></>)
                break;
            case "stockasc":
                sorted = [...beverages.sort((a, b) => a.stock - b.stock)]
                setButtonText(<>Bestand Aufsteigend <SortNumericUp /></>)
                break;
            case "stockdesc":
                sorted = [...beverages.sort((a, b) => b.stock - a.stock)]
                setButtonText(<>Bestand Absteigend <SortNumericDown /></>)
                break;
            case "alphabeticalasc":
                sorted = [...beverages.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0)]
                setButtonText(<>Alphabetisch <SortAlphaDownAlt /></>)
                break;
            case "alphabeticaldesc":
                sorted = [...beverages.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() < b.name.toUpperCase()) ? 1 : 0)]
                setButtonText(<>Alphabetisch <SortAlphaUp /></>)
                break;
            case 0:
                sorted = beverages
                setButtonText(<>Sortierung wählen <Filter /></>)
                break;
        }
        sortedBeverages(sorted);
    }

    return (
        <Dropdown drop={"down"}>
            <Dropdown.Toggle style={{width: "100%"}}
                             className={"filter-button"} id={"filter-dropdown"}
            >
                {buttonText}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSorting(0)}>Keine</Dropdown.Item>
                <Dropdown.Item onClick={() => setSorting("priceasc")}>Preis Aufsteigend</Dropdown.Item>
                <Dropdown.Item onClick={() => setSorting("pricedesc")}>Preis Absteigend</Dropdown.Item>
                <Dropdown.Item onClick={() => setSorting("stockasc")}>Bestand Aufsteigend</Dropdown.Item>
                <Dropdown.Item onClick={() => setSorting("stockdesc")}>Bestand Absteigend</Dropdown.Item>
                <Dropdown.Item onClick={() => setSorting("alphabeticalasc")}>Alphabetisch Aufsteigend</Dropdown.Item>
                <Dropdown.Item onClick={() => setSorting("alphabeticaldesc")}>Alphabetisch Absteigend</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

}
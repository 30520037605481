import {FC} from 'react';
import {Modal} from 'react-bootstrap';
import ManageBillsMain from './ManageBillsMain';

const ManageBillsModal: FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {

    return (
        <Modal show={show} onHide={hide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>(Benutzer-) Bestellungen Verwalten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ManageBillsMain />
            </Modal.Body>
        </Modal>
    )
}

export default ManageBillsModal

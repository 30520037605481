import {Accordion, Button, Col, Container, Modal, Row} from "react-bootstrap";
import {CashCoin, Eye, FileEarmarkCheckFill, PersonCheckFill} from "react-bootstrap-icons";
import React, {FC, useEffect, useMemo, useState} from "react";
import {backendURL} from "../../helpers";
import {Bill} from "../../PloneApi";
import BillAccordionItem from "./BillAccordionItem";
import {useGlobalState} from "../../state";

const PaymentMenueBody: FC<{ show: boolean }> = ({show}) => {
    const [allBills, setAllBills] = useState<Bill[]>([])
    const [filterMode, setFilterMode] = useState<string>("allBills")
    const {session} = useGlobalState()
    const [paypalLink, setPaypalLink] = useState<string>('')

    useEffect(() => {
        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "get_paypal_link"
            })
        })
            .then((r) => r.json() as Promise<string>)
            .then((newLink) => setPaypalLink(newLink))
    }, [session])

    useEffect(() => {
        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "get_user_bills"
            })
        })
            .then((res) => res.json() as Promise<Bill[]>)
            .then((bills: Bill[]) => {
                setAllBills(bills)
            })
    }, [show, session])


    const displayedBills = useMemo(() => {
        switch (filterMode) {
            case ("userPaidBills"):
                return allBills.filter(({user_paid, already_paid}) => user_paid && !already_paid)
            case ("fullyPaidBills"):
                return allBills.filter(({user_paid, already_paid}) => user_paid && already_paid)
            case ("unpaidBills"):
                return allBills.filter(({user_paid, already_paid}) => !user_paid && !already_paid)
            default:
                return allBills
        }
    }, [allBills, filterMode])

    const updateBill = (bill_uid: string) => {
        fetch(`${backendURL}/james_api`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "set_user_bill",
                "method_data": {
                    "wanted_bill_id": bill_uid,
                    "set_user_paid": true,
                }
            }),
            redirect: 'follow'
        })
            .then((res) => res.json() as Promise<Bill>)
            .then((newBill: Bill) => {
                console.log(newBill)
                setAllBills([...allBills.filter((bill) => bill.uid !== bill_uid), newBill])
            })
    }


    return <Modal.Body>
        <Container>
            <Row>
                <Col className={'dashboard-header'} xs={12}>{"Meine Rechnungen"}</Col>
            </Row>
            <Row className={"beverage-filters "} style={{padding: "0px 0px 20px 0px"}}>
                <Col xs={12} md={4} lg={2}>
                    <Button className={"filter-button"} onClick={() => {
                        setFilterMode("allBills")
                    }}><Eye/>Alle</Button>
                </Col>
                <Col xs={12} md={4} lg={2}>
                    <Button className={"filter-button"} onClick={() => {
                        setFilterMode("unpaidBills")
                    }}><CashCoin/>Unbezahlt</Button>
                </Col>
                <Col xs={12} md={4} lg={2}>
                    <Button className={"filter-button"} onClick={() => {
                        setFilterMode("userPaidBills")
                    }}><PersonCheckFill/>Bezahlt</Button>
                </Col>
                <Col xs={12} md={4} lg={2}>
                    <Button className={"filter-button"} onClick={() => {
                        setFilterMode("fullyPaidBills")
                    }}><FileEarmarkCheckFill/>Bestätigt</Button>
                </Col>
            </Row>
        </Container>
        <Container>
            <Accordion>
                {
                    displayedBills.map((bill, key) =>
                        <BillAccordionItem bill={bill} setUserPaid={(bill_uid) => updateBill(bill_uid)} key={key}
                                           paypalLink={paypalLink}/>)
                }
            </Accordion>
        </Container>
    </Modal.Body>
}

export default PaymentMenueBody
import {createContext, FC, useContext, useState} from "react";
import {Beverage, CartBeverage, LoginUser as User} from './PloneApi'

type GlobalStateValue =
    Pick<ReturnType<typeof useProvideGlobalState>,
        'cart' | 'session' | 'user' | 'beverages' > &
    Partial<Pick<ReturnType<typeof useProvideGlobalState>,
        'setCart' | 'setSession' | 'setUser' | 'resetUser' | 'setBeverages' | 'updateBeverage'>>

const globalStateContext = createContext<GlobalStateValue>({
    cart: [],
    beverages: [],
    session: null,
    user: {
        displayName: "",
        email: "",
        img: "",
        isAdmin: false,
        showStatistics: false,
        username: ""
    }
})

export const ProvideGlobalState: FC<{ children: JSX.Element }> = ({children}) => {
    const state = useProvideGlobalState()
    return <globalStateContext.Provider value={state}>{children}</globalStateContext.Provider>
}

export const useGlobalState = () => useContext(globalStateContext) as ReturnType<typeof useProvideGlobalState>


export const useProvideGlobalState = () => {
    const [cart, setCart] = useState<CartBeverage[]>([])
    const [beverages, setBeverages] = useState<Beverage[]>([])
    const [user, setUser] = useState<User>({
        displayName: "",
        email: "",
        img: "",
        isAdmin: false,
        showStatistics: false,
        username: ""
    })
    const [session, setSession] = useState<string | null>(null)


    const resetUser = () => {
        setUser({
            displayName: "",
            email: "",
            img: "",
            isAdmin: false,
            showStatistics: false,
            username: ""
        })
    }

    const updateBeverage = (ean: string, newBeverage: Beverage) => {
        setBeverages(beverages.map((beverage) => {
            if(beverage.ean === ean)
                return newBeverage
            return beverage
        }))
    }

    return {
        beverages, setBeverages, updateBeverage,
        cart, setCart,
        session, setSession,
        user, setUser, resetUser
    }
}

import React, {FC} from 'react'
import {Button, Modal} from 'react-bootstrap'
import PaymentMenueBody from "./PaymentMenueBody";

const PaymentMenueModal: FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {
    return (
        <Modal show={show} onHide={hide} backdrop={"static"} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Bezahlen</Modal.Title>
            </Modal.Header>

            <PaymentMenueBody {...{show}} />

            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={hide}>Zurück</Button>
            </Modal.Footer>

        </Modal>
    )
}

export default PaymentMenueModal


import {Button, CardImg, Col, Modal, Row} from 'react-bootstrap'
import React, {FC, useEffect, useState} from 'react'
import {Beverage} from '../../PloneApi'
import Nutritions from './Nutritions'

const BeverageCard: FC<{ beverage: Beverage, buy: (ean: string, amount?: number, username?: string) => Promise<void> }> = ({
                                                                                                                               beverage,
                                                                                                                               buy,
                                                                                                                           }) => {
    const [modal, setShowModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const handleCloseBuyModal = () => setShowBuyModal(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        if (confirmation)
            setTimeout(() => setConfirmation(false), 3000);
    }, [confirmation])

    useEffect(() => {
        if (beverage.stock < 1) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [beverage.stock])

    return (
        <>
            <Col className={"beverage-card"} md={4} xs={12} lg={3}>
                <div className={"card-body"}>
                    <CardImg variant="top" className="cardimg" src={beverage.img} onClick={handleShowModal}/>
                    <div className={"title"}>
                        {beverage.name}
                    </div>
                    <div className={"price"}>
                        {beverage.price} €
                    </div>
                    <div className={"volume"}>
                        {beverage.volume} ml
                    </div>
                    <Button className={"buy-button"} onClick={() => setShowBuyModal(true)} disabled={disabled}>
                    <span className={"text"}>
                        Kaufen
                    </span>
                    </Button>
                    <div className={"stock"} data-active={confirmation}>
                        <div className={"text"}>
                            {beverage.stock}
                        </div>
                    </div>
                </div>
            </Col>
            <Modal show={modal} onHide={handleCloseModal} dialogClassName={"modal-lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Nährwerte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Nutritions nutritions={beverage.nutritions}/>
                    <small className="text-muted">{"EAN: " + beverage.ean}</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showBuyModal} onHide={handleCloseBuyModal}
                   aria-labelledby="contained-modal-title-vcenter" centered closeButton>
                <Modal.Body className={"buyModal-Body"}>
                    <Row className={"buyModal-headline"}>
                        <Col xs={12}>
                                <span>
                                    Kaufen ?
                                </span>
                        </Col>
                    </Row>
                    <Row className={"buyModal-content"}>
                        <Col xs={6}>
                            <Button onClick={() => setShowBuyModal(false)} className={"notBuy-Button"}
                                    style={{width: "100%", height: "100%", marginTop: "0px"}}>
                                Nee,doch nicht
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button onClick={() => {
                                buy(beverage.ean, 1)
                                setShowBuyModal(false)
                                setConfirmation(true)
                            }} className={"buy-button"} style={{width: "100%", height: "100%", marginTop: "0px"}}>
                                Kaufen
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BeverageCard;


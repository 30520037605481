import React, {FC, useEffect, useState} from 'react';
import {Button, ButtonGroup, Table} from "react-bootstrap";
import {backendURL} from '../../helpers';
import ManageBillsRow from "./ManageBillsRow";
import {BillData} from "../../PloneApi";
import {useGlobalState} from "../../state";


const ManageBillsMain: FC = () => {
    const {session} = useGlobalState()
    const [allBills, setAllBills] = useState<BillData[]>([])
    const [billsList, setBillsList] = useState<BillData[]>(allBills)

    useEffect(() => {
        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                "method": "get_all_bills",
                "method_data": null
            })
        })
            .then(r => r.json() as Promise<BillData[]>)
            .then(res => {
                setAllBills(res)
                setBillsList(res)
            })
    }, [session])

    const getBills = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, filter: "all" | "paid" | "open" | "unaccepted") => {
        event.preventDefault()
        if (filter === "all")
            setBillsList(allBills)
        if (filter === "paid")
            setBillsList(allBills.filter((billData) => billData.already_paid && billData.user_paid))
        if (filter === "unaccepted")
            setBillsList(allBills.filter((billData) => !billData.already_paid && billData.user_paid))
        if (filter === "open")
            setBillsList(allBills.filter((billData) => !billData.already_paid && !billData.user_paid))
    }

    return (
        <>
            <ButtonGroup>
                <Button variant="primary" type="submit" onClick={(event) => getBills(event, "all")}
                        title="Alle Rechnungen anzeigen">Alle Rechnungen</Button>
                <Button variant="warning" type="submit" onClick={(event) => getBills(event, "unaccepted")}
                        title="Unbestätigte Rechnungen">Unbestätigte Rechnungen</Button>
                <Button variant="danger" type="submit" onClick={(event) => getBills(event, "open")}
                        title="Unbezahlte Rechnungen">Unbezahlte Rechnungen</Button>
                <Button variant="success" type="submit" onClick={(event) => getBills(event, "paid")}
                        title="Bezahlte Rechnungen">Bezahlte Rechnungen</Button>
            </ButtonGroup>
            <Table hover={true} responsive>
                <thead>
                <tr>
                    {['Datum', 'Titel', 'Betrag', 'Benutzer', 'RechnungsNr.', 'Bezahlt', 'Bestätigt'].map(
                        (name, idx) => <th key={idx}>{name}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {
                    billsList.map((bill, idx) => <ManageBillsRow key={idx} billData={bill}/>)
                }
                </tbody>
            </Table>
        </>
    )
}

export default ManageBillsMain

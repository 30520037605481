import AutoLogout from './components/login/AutoLogout'
import UserButton from './components/UserButton'
import Dashboard from './components/dashboard/Dashboard'
import {FC} from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./components/login/Login";
import PaymentMenue from "./components/payment/PaymentMenue";
import {useGlobalState} from "./state";

const App: FC = () => {
    const {session, user} = useGlobalState()
    const showLogin = (element: JSX.Element): JSX.Element => {
        if (user === null || session === null) {
            return <Login/>
        } else {
            return element
        }
    }
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/payment" element={showLogin(<PaymentMenue/>)}/>
                    <Route path="/" element={showLogin(<>
                        <AutoLogout/>
                        <Dashboard/>
                        <UserButton/>
                    </>)}>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App

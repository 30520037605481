import {Button, Dropdown, Form} from "react-bootstrap";
import {Funnel} from "react-bootstrap-icons";
import React, {FC, useEffect, useMemo, useState} from "react";
import {Beverage, BeverageType} from "../../PloneApi";

export const FilterDropdown: FC<{ beverages: Beverage[], filteredBeverages: Function }> = ({
                                                                                               beverages,
                                                                                               filteredBeverages
                                                                                           }) => {
    const [stockFilter, setStockFilter] = useState<boolean>(true)

    const initTypeFilters: Record<"energy" | "softdrink" | "beer" | "juice" | "mate" | "other", boolean> = useMemo(() => {
        return {
            energy: true,
            softdrink: true,
            beer: true,
            juice: true,
            mate: true,
            other: true
        }
    }, [])
    const [typeFilter, setTypeFilterObject] = useState(initTypeFilters)
    const setTypeFilter = (key: keyof typeof typeFilter, value: boolean) => setTypeFilterObject(prevState => {
        return {...prevState, [key]: value}
    })

    const typeFilterLabels = useMemo((): Record<keyof typeof typeFilter, string> => {
        return {
            energy: "Energydrinks",
            softdrink: "Softgetränke",
            beer: "Biere",
            juice: "Säfte",
            mate: "Mate",
            other: "Andere"
        }
    }, [])

    const resetFilters = () => {
        setStockFilter(true)
        setTypeFilterObject(initTypeFilters)
    }

    useEffect(() => {
        filter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockFilter, typeFilter, beverages])

    const filter = () => {
        const filterFor: BeverageType[] = []
        if (typeFilter.energy) filterFor.push('monster', 'energydrink')
        if (typeFilter.beer) filterFor.push('beer', 'nonAlcoholicBeer')
        if (typeFilter.juice) filterFor.push('juice')
        if (typeFilter.softdrink) filterFor.push('softdrink')
        if (typeFilter.mate) filterFor.push('mate')
        if (typeFilter.other) filterFor.push('other')
        let filtered = beverages.filter(({type}) => filterFor.some(it => it === type))
        if (stockFilter)
            filtered = filtered.filter(({stock}) => stock > 0)
        filteredBeverages(filtered)
    }
    return (
        <Dropdown autoClose={"outside"}>
            <Dropdown.Toggle style={{width: "100%"}} className={"filter-button"} id={"filter-dropdown"}>
                Filter <Funnel />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{maxWidth: "100%", minWidth: "300px"}}>
                <div style={{padding: "10px 20px 10px 20px"}}>
                    <Form>
                        <Form.Label><b>Getränke</b></Form.Label>
                        <div style={{padding: "0 10px 0 10px"}}>
                            {
                                Object.entries(typeFilter).map(([key, value]) =>
                                    <Form.Check type={"checkbox"}
                                                checked={value}
                                                onChange={({target: {checked}}) => setTypeFilter(key as keyof typeof typeFilter, checked)}
                                                label={typeFilterLabels[key as keyof typeof typeFilter]}
                                                key={key} />
                                )
                            }
                        </div>
                        <Form.Label><b>Verfügbarkeit</b></Form.Label>
                        <div style={{padding: "0 10px 0 10px"}}>
                            <Form.Check type={"checkbox"}
                                        checked={stockFilter}
                                        onChange={() => setStockFilter(prevState => !prevState)}
                                        label={"Nur Verfügbare Anzeigen"} />
                        </div>
                        <Button style={{width: "100%", marginTop: "10px"}} onClick={resetFilters}>Filter
                            zurücksetzen</Button>
                    </Form>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
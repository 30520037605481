import {Accordion, Alert, Button, Col, Row, Table} from "react-bootstrap";
import {CashCoin, FileEarmarkCheckFill, PersonCheckFill} from "react-bootstrap-icons";
import AccordionBody from "react-bootstrap/AccordionBody";
import QRCode from "react-qr-code";
import React, {FC} from "react";
import {Bill} from "../../PloneApi";

const priceFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
}).format

const dateFormatter = (dateString: string) => `${new Intl.DateTimeFormat('de-DE', {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
}).format(new Date(dateString))} Uhr`

const BillAccordionItem: FC<{ bill: Bill, setUserPaid: (bill_uid: string) => void , paypalLink: string}> = ({bill, setUserPaid, paypalLink}) => {

    return (
        <Accordion.Item eventKey={bill.uid} key={bill.uid}>
            <Accordion.Header>{bill.date}
                {bill.user_paid && bill.already_paid ? <><PersonCheckFill style={{
                        color: "green",
                        marginLeft: "20px"
                    }} /><FileEarmarkCheckFill style={{
                        color: "green",
                        marginLeft: "20px"
                    }} /></> :
                    bill.user_paid ? <PersonCheckFill style={{color: "green", marginLeft: "20px"}} /> :
                        <CashCoin style={{color: "black", marginLeft: "20px"}} />
                }
            </Accordion.Header>
            <AccordionBody>
                {bill.user_paid && bill.already_paid ? <>
                    <Alert variant={"success"}>Diese Bestellung ist bereits bezahlt!</Alert>
                </> : <></>
                }
                <Row>
                    <Col md={9}> <Table striped>
                        <thead>
                            <tr>
                                <td>
                                    Datum
                                </td>
                                <td>
                                    Getränk
                                </td>

                                <td>
                                    Anzahl
                                </td>
                                <td>
                                    Preis / Stk.
                                </td>
                                <td>
                                    Summe
                                </td>

                            </tr>
                        </thead>
                        <tbody>
                            {bill.order_list.map((order, key) => (
                                <tr key={key}>
                                    <td>
                                        {dateFormatter(order.date)}
                                    </td>
                                    <td>
                                        {order.beverage_name}
                                    </td>

                                    <td>
                                        {order.ordered_sum}
                                    </td>
                                    <td>
                                        {priceFormatter(order.single_price)}
                                    </td>
                                    <td>
                                        {priceFormatter(order.sum_price)}
                                    </td>
                                </tr>
                            ))}
                            <tr style={{backgroundColor: "#000", color: "#fff"}}>
                                <td />
                                <td />
                                <td />
                                <td className={"text-end"}>Betrag gesamt:</td>
                                <td>{priceFormatter(bill.bill_price_sum)}</td>
                            </tr>
                        </tbody>
                    </Table></Col>
                    <Col md={3} className={"text-center"}>
                        {!bill.user_paid ? <Table borderless>
                            <thead>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <QRCode className={"text-center"}
                                                value={`https://paypal.me/${paypalLink}/${bill.bill_price_sum}`} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button
                                            variant={"success"}
                                            onClick={() => setUserPaid(bill.uid)}
                                            key={bill.uid}>Ich
                                            habe
                                            meine Rechnung bezahlt</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table> : <></>
                        }
                    </Col>
                </Row>
            </AccordionBody>
        </Accordion.Item>
    )
}

export default BillAccordionItem
import {FC, useState} from 'react';
import {BillData} from "../../PloneApi";
import {Badge, Button} from "react-bootstrap";
import {backendURL} from "../../helpers";
import {useGlobalState} from "../../state";

const ManageBillsRow: FC<{ billData: BillData }> = ({billData}) => {
    const { session } = useGlobalState()
    const [newBillData, setNewBillData] = useState(billData)

    const setBillPaid = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json',
                redirect: 'follow',
            },
            body: JSON.stringify({
                "method": "set_user_bill",
                "method_data": {
                    "wanted_bill_id": newBillData.bill_uid,
                    "set_already_paid": true,
                }
            })
        }).then(r => r.json()).then(res => setNewBillData(res))
    }

    const setBillUnPaid = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json',
                redirect: 'follow',
            },
            body: JSON.stringify({
                "method": "set_user_bill",
                "method_data": {
                    "wanted_bill_id": newBillData.bill_uid,
                    "set_already_paid": false,
                }
            })
        }).then(r => r.json()).then(res => setNewBillData(res))
    }

    return (
        <tr key={newBillData.bill_uid}>
            <td>{newBillData.date}</td>
            <td>{newBillData.title}</td>
            <td>{newBillData.bill_price_sum}</td>
            <td>{newBillData.owner_id}</td>
            <td>{newBillData.bill_uid}</td>
            <td>{newBillData.user_paid ?
                <Badge pill bg={"success"}>Bezahlt</Badge> :
                <Badge pill bg={"danger"}>Unbezahlt</Badge>}
            </td>
            <td>
                {newBillData.already_paid ?
                    <Button variant="success" type="submit" onClick={(event) => setBillUnPaid(event)}
                            title="Widerrufen">Widerrufen</Button>
                    :
                    <Button variant="danger" type="submit" onClick={(event) => setBillPaid(event)}
                            title="Bestätigen">Bestätigen</Button>
                }
            </td>
        </tr>
    );
}

export default ManageBillsRow

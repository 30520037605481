import {Col, Row, Spinner} from 'react-bootstrap'
import React, {FC, useEffect, useState} from 'react'
import {Statistics, StatType} from '../../PloneApi'
import {backendURL} from "../../helpers";
import {useGlobalState} from "../../state";

const StatisticsCard: FC = () => {

    const [statistic, setStatistic] = useState<Record<StatType, Statistics.Any>>()
    const {session} = useGlobalState()

    useEffect(() => {
        fetch(`${backendURL}/james_api`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "method": "get_statistics"
            })
        }).then(async (res) => {
            if (res.status !== 200)
                await Promise.reject(await res.text())
            else return await res.json() as Array<Record<StatType, Statistics.Any>>
        }).then(stats => {
                setStatistic(stats![Math.round(Math.random() * (Object.keys(stats!).length - 1))])
            }
        )
    }, [session])


    const cardBody = (head: string, body: string | JSX.Element | JSX.Element[]) => {
        return <div className={"card-body"}>
            <div className={"card-content"}>
                <div className={"head"}>
                    {head}
                </div>
                <div className={"body"}>
                    {body}
                </div>
            </div>
        </div>
    }


    const getBody = (stat: string, load: Statistics.Any): string | JSX.Element | JSX.Element[] => {
        switch (stat) {
            case "motd" as "motd":
                load = load as Statistics.MotD
                return cardBody("!?", load)

            case "userBeverageBoughtRanking":
                load = load as Statistics.userBeverageBoughtRanking
                return cardBody("Investoren",
                    <Row>
                        <Col>
                            {load.current_user}
                            {load.current_rank}
                            {load.next_user1}
                            {load.next_user2}
                            {load.place}
                        </Col>
                    </Row>
                )

            case "totalBeverageBoughtRanking":
                load = load as Statistics.totalBeverageBoughtRanking
                return cardBody("Gekauft",
                    load.map((elem, idx) => (
                        <Row key={idx}>
                            <Col xs={2}>
                                {
                                    idx === 0 ? "🥇" :
                                        idx === 1 ? "🥈" :
                                            idx === 2 ? "🥉" : ""
                                }
                            </Col>
                            <Col xs={6} style={{textAlign: "left"}}>
                                {elem.username}
                            </Col>
                            <Col xs={4} style={{textAlign: "right"}}>
                                {elem.beverage_count}
                            </Col>
                        </Row>))
                )

            case "totalBeveragesBought":
                load = load as Statistics.totalBeveragesBought
                return cardBody("Respekt!!", "Du hast schon " + load + " Geränke gekauft.")

            case "beverageSoldEach":
                load = load as Statistics.beveragesSoldEach
                return cardBody("Verkaufte Getränke", (load).map(
                    (elem, idx) => <Row key={idx} style={{marginBottom: "10px"}}>
                        <Col xs={2}>
                            {idx === 0 ? "🥇" :
                                idx === 1 ? "🥈" :
                                    idx === 2 ? "🥉" : ""}
                        </Col>
                        <Col xs={8}>
                            {
                                elem.name
                            }
                        </Col>
                        <Col xs={2}>
                            {elem.sold}
                        </Col>
                    </Row>))

            case "totalMonsterRanking":
                load = load as Statistics.totalMonsterRanking
                return cardBody("Monster Ranking!", (load).map(
                    (elem, idx) => <Row key={idx}>
                        <Col>
                            <Row>
                                <Col xs={2}>
                                    {idx === 0 ? "🥇" :
                                        idx === 1 ? "🥈" :
                                            idx === 2 ? "🥉" : ""}
                                </Col>
                                <Col xs={5}>
                                    {elem.username}:
                                </Col>
                                <Col xs={5}>
                                    {elem.score}
                                </Col>
                            </Row>
                        </Col>
                    </Row>))

            case "userMonsterScore":
                load = load as Statistics.userMonsterScore
                return cardBody("M-Score", "Dein Score: " + load)

            default:
                return <></>
        }
    }

    return (
        <Col className={"statistic-card"} md={4} xs={12} lg={3}>
            {
                statistic === undefined ?
                    <Spinner animation={"border"}/>
                    :
                    getBody(Object.keys(statistic!)[0], Object.values(statistic!)[0])
            }
        </Col>
    )
}

export default StatisticsCard

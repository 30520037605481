export const backendURL = process.env.REACT_APP_BACKEND_URL

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export const api = async <T>(path: string, method: HttpMethod = 'GET', auth?: string, body?: object) => new Promise<T>((resolve, reject) => {
    const init: RequestInit = {
        method,
        headers: {
            Accept: 'application/json'
        }
    }
    if (auth) (init.headers as Record<string, string>).Authorization = `Bearer ${auth}`
    if (body) {
        init.body = JSON.stringify(body);
        (init.headers as Record<string, string>)['Content-Type'] = 'application/json';
    }

    fetch(`${backendURL}/${path}`, init)
        .then(async (res) => {
            if (res.status.toString()[0] !== '2') {
                reject({
                    status: res.status,
                    reason: await res.json()
                })
            } else {
                resolve(await res.json() as Promise<T>)
            }
        })
})

export const class2Obj = <T, K extends keyof T>(classObj: T, ...keysInObj: K[]) => {
    return Object.fromEntries(
        Object.entries(classObj).filter(([key]) => key in keysInObj)
    ) as Pick<T, K>
}

export const compStrings = (a: string, b: string): -1 | 0 | 1 => {
    const [aN, bN] = [
        a.split('').map(it => it.charCodeAt(0)),
        b.split('').map(it => it.charCodeAt(0))
    ]

    if (aN.length > bN.length) {
        for (let c = 0; c < bN.length; c++) {
            const [aC, bC] = [aN[c], bN[c]]
            if (aC < bC) return -1
            else if (aC > bC) return 1
        }
        return 1
    } else if (aN.length < bN.length) {
        for (let c = 0; c < aN.length; c++) {
            const [aC, bC] = [aN[c], bN[c]]
            if (aC < bC) return -1
            else if (aC > bC) return 1
        }
        return -1
    } else {
        for (let c = 0; c < aN.length; c++) {
            const [aC, bC] = [aN[c], bN[c]]
            if (aC < bC) return -1
            else if (aC > bC) return 1
        }
        return 0
    }
}

export const fixedLengthNumber = (number: number | string, length: number): string => {
    let num = parseInt(number as string).toString()
    while (num.length < length) num = '0' + num
    return num
}

export const getQuery = (): Record<string, string> => Object.fromEntries(window.location.search.substr(1).split('&').map(pair => pair.split('=')))